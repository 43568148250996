import { CertificadoAsignado, Item } from '../_models/certificados';
import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'detalle-dialog',
  templateUrl: './detalle.dialog.html',
  styleUrls: ['./certificado.component.css']
})
export class DetalleDialog {

  public cert: CertificadoAsignado;
  public show_actions: boolean = false;
  public items: Item[] = [];
  public elaboracion: string;
  public vencimiento: string;
  public read_only: boolean = false;
  public rechazar_certificado: boolean = false;
  public messages: any = {
    'certificado_rechazado': "Certificado Rechazado"
  }
  itemsForm = new FormArray([]);
  frascosForm = new FormGroup({
    elaboracion: new FormControl({value: '', disabled: true}),
    vencimiento: new FormControl({value: '', disabled: true}),
    rechazar_certificado: new FormControl({value: '', disabled: false}),
    rechazar_certificado_descripcion: new FormControl({value: '', disabled: false}),
    items: this.itemsForm
  });


  constructor(private dialogRef: MatDialogRef<DetalleDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.cert = data.certificado;
    this.show_actions = data.show_actions;
    this.read_only = data.read_only;
    this.rechazar_certificado = data.rechazado;
    this.frascosForm.get('elaboracion').setValue(data.certificado.elaboracion);
    this.frascosForm.get('vencimiento').setValue(data.certificado.vencimiento);
    this.frascosForm.get('rechazar_certificado').setValue(data.rechazado);
    this.frascosForm.get('rechazar_certificado_descripcion').setValue(data.rechazo_descripcion);
    this.frascosForm.get('rechazar_certificado').valueChanges.subscribe((value) => {
      this.rechazar_certificado = value;
    })
    if(data.read_only) {
      this.frascosForm.get('rechazar_certificado').disable();
      this.frascosForm.get('rechazar_certificado_descripcion').disable();
    }
    data.items.forEach(el => {
      let lote: FormControl =  new FormControl({value: el.lote, disabled: true});
      // lote.setValue(el.lote);
      let serie: FormControl =  new FormControl({value: el.serie, disabled: true});
      // serie.setValue(el.serie);
      this.itemsForm.push(new FormGroup({
        lote: lote,
        serie: serie
      }));
    });
  }

  onNoClick(): void {
    this.dialogRef.close({cerrar: true, cert: this.cert});
  }

  onConfirmar(): void {
    if(confirm("Esta seguro de confirmar el certificado?"))
    {
      this.dialogRef.close({confirmar: true, cert: this.cert});
    }
  }

  onRechazar(): void {
    let motivo_rec: string = this.frascosForm.get('rechazar_certificado_descripcion').value;
    if(motivo_rec == "") {
      alert("Debe completar el campo descripción antes de rechazar el certificado.");
      return;
    }
    if(confirm("Esta seguro de rechazar el certificado?"))
    {
      this.dialogRef.close({rechazar: true, cert: this.cert, detalle: motivo_rec});
    }
  }

}
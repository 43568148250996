/*
{
    "registros": [
        {
            "nromatricu": 1297,
            "razon_soci": "FORTUNA, ALFREDO EMILIO",
            "cuit": "-        -",
            "numero_doc": 17897025,
            "domicilio": "RIVADAVIA 636 0 0",
            "barrio": "",
            "codigo_pos": "2253",
            "telefonos": "03404-420244",
            "tel_cel": "03404-497115",
            "e_mail": "fortunaalfredo@gmail.com",
            "vendedor": 0
        }
    ],
    "statussalida": "",
    "ctimestamp": 1542323295
}
*/
export class MatriculadoRegistro {
    nromatricu: number;
    razon_soci: string;
    cuit: string;
    numero_doc: number;
    domicilio: string;
    barrio: string;
    codigo_pos: string;
    telefonos: string;
    tel_cel: string;
    e_mail: string;
    vendedor: number;
    localid: string;
    saldo: number;
}
export class MatriculadoResponse {
    registros: MatriculadoRegistro[];
    statussalida: string;
    ctimestamp: number;
}

export class TipoCertificado {
  tipo: number;
  nombre: string;
  habilitado: string;
  nombre_con: string;

  static map(list: TipoCertificado[])
  {
    let _map: any = {};
    list.forEach((e: TipoCertificado) => {
      _map[e.tipo] = e;
    });
    return _map;
  }
}

export class CertificadoPendiente {
  certificado: number;
  tipo: number;
  nombrecer: string;
  nombrecon: string;
  medicamento: string;
  distribuidora: string;
  ente: string;
  procesando: boolean;
  mostrar: boolean;
  frascos: number;
}

export class Ente {
  ente: number;
  nombre: string;
  domicilio: string;

  static map(list: Ente[])
  {
    let _map: any = {};
    list.forEach((e: Ente) => {
      _map[e.ente] = e;
    });
    return _map;
  }
}

export class Responsable {
  distribuidora: number;
  nombre: string;
  domicilio: string;
  static map(list: Responsable[])
  {
    let _map: any = {};
    list.forEach((e: Responsable) => {
      _map[e.distribuidora] = e;
    });
    return _map;
  }
}

export class Droga {
  medicamento: number;
  nombre: string;
  certificado: string;
  nombreqpa: string;
  lista: string;
  formaf: string;
  denominacionpa: string;
  envase: string;

  static map(list: Droga[])
  {
    let _map: any = {};
    list.forEach((e: Droga) => {
      _map[e.medicamento] = e;
    });
    return _map;
  }
}

export class CertificadoAsignado {
  tipo: number;
  certificado: number;
  nro_asocia: number;
  fecha_gene: string;
  fecha_emis: string;
  distribuid: number;
  ente: number;
  medicament: number;
  serie_lote: string;
  fecha_de_e: any;
  fecha_de_v: any;
  numero_de_: string;
  imagen: string;
}

export class Item {
  lote: string;
  serie: string;
  constructor()
  {
    this.lote = "";
    this.serie = "";
  }
}

export class CertificadoAsignadoRequest {
  tipo: number;
  certificado: number;
  medicamento: number;
  distribuidora: number;
  ente: any;
  // serie: string;
  elaboracion: string;
  vencimiento: string;
  nrofactura: number;
  items: Item[];

  constructor(tipo: number, certificado: number, medicamento: number,
              distribuidora: number, ente: number, items: Item[], elaboracion: string, vencimiento: string)
  {
    this.tipo = tipo;
    this.certificado = certificado;
    this.ente = ente;
    this.medicamento = medicamento;
    this.distribuidora = distribuidora;
    // this.serie = "";
    this.elaboracion = elaboracion;
    this.vencimiento = vencimiento;
    this.nrofactura = 0;
    this.items = items;
  }

  static getCert(cert: CertificadoPendiente, items: Item[], elaboracion: string, vencimiento: string)
  {
    return new CertificadoAsignadoRequest(cert.tipo, cert.certificado, parseInt(cert.medicamento),
                                          parseInt(cert.distribuidora), parseInt(cert.ente), items, elaboracion, vencimiento);
  }

}

export class CertificadoRespuesta {
  registros: any[];
  statussalida: string;
  camposseguros: any[];
  ctimestamp: number;
  estado: string;
}

import { VentaRegistro } from './../_models/user';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MatriculadoRegistro } from '../_models';

@Injectable({ providedIn: 'root' })
export class ComprobanteService {

    constructor(private http: HttpClient) { }

    getComprobante(registro: VentaRegistro, matriculado: MatriculadoRegistro, today: boolean) {
        /*const param = {
            primer_vencimiento: '2018/12/10',
            importe: 800,
            matriculado: 'Francisco Giana',
            matricula: 123456789,
            direccion: 'Cervantes 640',
            localidad: 'Cordoba',
            factura_nro: 'F0001X002369',
            barcode: 'Francisco Giana'
        };*/
        const barcode = this.generateBarcode(registro, matriculado, today, registro.fec_vcto);
        let _shift = (barcode.length > 35)?4:0;
        const importe = parseInt(barcode.substr(8, 6 + _shift), 10) / 100;
        const primer_vencimiento = barcode.substr(20 + _shift, 2) + '/' + barcode.substr(18 + _shift, 2);

        const param = {
            vencimiento: primer_vencimiento,
            importe: importe,
            matriculado: registro.razon_soci,
            matricula: matriculado.nromatricu,
            direccion: matriculado.domicilio,
            localidad: matriculado.localid,
            factura_nro: 'F000' + registro.origen + registro.tipo.toLocaleUpperCase() + '00' + registro.nrocomprob,
            barcode: barcode,
            observacion: registro.observacion
        };
        const headers = new HttpHeaders().set('content-type', 'application/json');
        return this.http.post<any>(environment.apiUrlComprobante, param , {headers: headers});
    }
    generateBarcode(registro: VentaRegistro, matriculado: MatriculadoRegistro, today: boolean, fecha_vencimiento: string) {
        /*
            El método va a genarar el código de barras unicamente cuando el documento este vencido
            y el matriculado haya seleccionado plazo de 1 día.
            En todos los otros casos se va a devolver el valor recibido desde el backend registro.codpf
        */
        const hoy = new Date();
        const vencimiento_dia = parseInt(fecha_vencimiento.substr(8, 2), 10);
        const vencdiamesactual = vencimiento_dia-5;
        const vencimiento_mes = parseInt(fecha_vencimiento.substr(5, 2), 10) - 1;
        const vencimiento_anio = parseInt(fecha_vencimiento.substr(0, 4), 10);
        const vencimiento_del_mes = new Date(vencimiento_anio, vencimiento_mes, vencimiento_dia, 20, 59, 59);
        const vencimiento_mes_cta_mes= new Date(vencimiento_anio, vencimiento_mes, vencdiamesactual, 20, 59, 59);
        let vencimiento = vencimiento_del_mes;
        /*
        const anio1=hoy.getFullYear(); 
        const anio2=vencimiento_del_mes.getFullYear(); 
        const mes1=hoy.getMonth(); 
        const mes2=vencimiento_del_mes.getMonth(); 
        const hoy1=hoy.getTime(); 
        const hoy2=vencimiento_mes_cta_mes.getTime();   
        */
        if (today === false)
        {
            if (hoy.getFullYear() == vencimiento_del_mes.getFullYear() && hoy.getMonth() == vencimiento_del_mes.getMonth() && hoy.getTime()<=vencimiento_mes_cta_mes.getTime())           
                {
                let bcode = '90060266';
                vencimiento = vencimiento_del_mes;
                let saldo:number = Math.floor(registro.saldo * 100);
                let interes:number = Math.floor(registro.interes * 100);
                let importe:number = saldo + interes;
                bcode += importe.toString().padStart(10, '0');
                bcode += vencimiento.getFullYear();
                bcode += (vencimiento.getMonth() + 1).toString().padStart(2, '0');
                bcode += vencimiento.getDate().toString().padStart(2, '0');
                bcode += registro.asociado.toString().padStart(5, '0');
                bcode += registro.nrocomprob.toString().padStart(8, '0');
                return bcode;
            }
            return registro.codpf;  
        }
        let bcode = '90060266';
        /*const hoy = new Date();
        const vencimiento_dia = parseInt(fecha_vencimiento.substr(8, 2), 10);
        const vencimiento_mes = parseInt(fecha_vencimiento.substr(5, 2), 10) - 1;
        const vencimiento_anio = parseInt(fecha_vencimiento.substr(0, 4), 10);
        const vencimiento_del_mes = new Date(vencimiento_anio, vencimiento_mes, vencimiento_dia, 20, 59, 59);
        let vencimiento = vencimiento_del_mes; */
        /*if ( hoy.getTime() <= vencimiento_del_mes.getTime())
        {
            return registro.codpf;
        }*/
        vencimiento = hoy;
        let saldo:number = Math.floor(registro.saldo * 100);
        let interes:number = Math.floor(registro.interes * 100);
        let importe:number = saldo + interes;
        bcode += importe.toString().padStart(10, '0');
        bcode += vencimiento.getFullYear();
        bcode += (vencimiento.getMonth() + 1).toString().padStart(2, '0');
        bcode += vencimiento.getDate().toString().padStart(2, '0');
        bcode += registro.asociado.toString().padStart(5, '0');
        bcode += registro.nrocomprob.toString().padStart(8, '0');
        return bcode;
    }

    barcode2of5 (code): number {
        let sum = 0;
        let h = [1, 3, 5, 7, 9];
    
        for (let i = 0, n = 0; i < code.length; i++, n++) {
            if (n > 4)
                n = 1;
            sum += parseInt(code.charAt(i), 10) * h[n];
        }
        let d1 = Math.floor(sum / 2);
        let d = (d1 % 10);
        return d;
    }

}

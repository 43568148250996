import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import { MatriculadoService } from '../_services/matriculado.service';

@Component({
  selector: 'app-comunicacion',
  templateUrl: './comunicacion.component.html',
  styleUrls: ['./comunicacion.component.css']
})
export class ComunicacionComponent implements OnInit {

  matriculado = null
  loading: boolean = false;
  curr_user: string;
  actualizar: boolean = false;
  form: FormGroup = new FormGroup({
    action: new FormControl('',[Validators.required])
  });
  constructor(private auth: AuthenticationService,
              private service: MatriculadoService,
              private router: Router ) {
    this.service.matriculado.subscribe( m => {
      this.matriculado = m;
      this.loading = false;
    });
    this.loading = true;
    this.curr_user = this.auth.get_user();
    this.service.loadMatriculado();
  }

  ngOnInit() {

  }

  onSubmit() {
    if(!confirm("Esta que quiere continuar?"))
    {
      return;
    }
    if(this.form.get('action').value == 'cambiar') {
      this.actualizar = true;
    }else if(this.form.get('action').value == 'actual') {
      this.auth.setComunication(false);
      this.router.navigate(["/"]);
    }
  }
}

import { CertificadoService } from './../../_services/certificados.service';
import { CertificadoPendiente, CertificadoRespuesta, CertificadoAsignado,
         TipoCertificado, Droga, Ente, Responsable } from './../../_models/certificados';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DetalleDialog } from './../../certificado/certificado.dialog-detalle.component';

@Component({
  selector: 'app-certificados',
  templateUrl: './certificado.component.html',
  styleUrls: ['./certificado.component.css']
})
export class CertificadoDistribuidoraComponent implements OnInit {

  asignados: CertificadoAsignado[];
  rechazados: CertificadoAsignado[];
  confirmados: CertificadoAsignado[];
  drogas: Droga[];
  entes: Ente[];
  responsables: Responsable[];
  tipo: TipoCertificado[];
  cantidad_a_liquidar: number = 0;
  tipo_seleccionado: number = 0;
  loadingAsignados: boolean = false;
  loadingRechazados: boolean = false;
  loadingConfirmados: boolean = false;
  liquidando: boolean = false;
  serverError: boolean = false;
  err_msg: string[] = [];
  titular: Ente = {'ente': 0, nombre: 'TITULAR', domicilio: ''};
  maps: any = {
    tipos: {},
    medicamentos: {},
    distribuidoras: {},
    entes: {}
  }
  private subscription = [];

  constructor(private cert: CertificadoService, private dialog: MatDialog) {
    this.loadingAsignados = true;
    this.loadingRechazados = true;
    this.loadingConfirmados = true;


    this.subscription.push(
      this.cert.certConfirmado.subscribe((resp: {resp: CertificadoRespuesta, cert: CertificadoAsignado}) => {
        // console.log(resp);
        resp.cert["procesando"] = false;
        window.alert("El certificado ha sido confirmado");
        this.cert.getAsignados(true);
        this.cert.getRechazados(true);
        this.cert.getConfirmados(true);    
      })
    );

    this.subscription.push(
      this.cert.certRechazado.subscribe((resp: {resp: CertificadoRespuesta, cert: CertificadoAsignado}) => {
        // console.log(resp);
        resp.cert["procesando"] = false;
        window.alert("El certificado ha sido rechazado");
        this.cert.getAsignados(true);
        this.cert.getRechazados(true);
        this.cert.getConfirmados(true);    
      })
    );

    this.subscription.push(
      this.cert.error_servidor.subscribe((resp: CertificadoRespuesta) => {
        console.log(resp);
        this.serverError = true;
        this.loadingAsignados = false;
        this.liquidando = false;
        if(resp.statussalida !== undefined && resp.statussalida !== "")
        {
          this.err_msg.push(resp.statussalida);
        }
      })
    );


    this.subscription.push(
      this.cert.asignacion.subscribe((resp: any) => {
        console.log(resp);
        if(resp.status === "")
        {
          let cert: CertificadoPendiente = resp["certificado"];
          cert.procesando = false;
          cert.mostrar = false;
          this.loadingAsignados = true;
          this.cert.getAsignados();
        }
      })
    );

    this.subscription.push(
      this.cert.confirmados.subscribe((resp: CertificadoRespuesta) => {
        this.confirmados = resp.registros.sort((a, b) => {
          return b['certificado'] - a['certificado'];
        });
        this.loadingConfirmados = false;
      })
    );

    this.subscription.push(
      this.cert.rechazados.subscribe((resp: CertificadoRespuesta) => {
        this.rechazados = resp.registros.sort((a, b) => {
          return b['certificado'] - a['certificado'];
        });
        this.loadingRechazados = false;
      })
    );


    this.subscription.push(
      this.cert.asignados.subscribe((resp: CertificadoRespuesta) => {
        this.asignados = resp.registros;
        this.loadingAsignados = false;
      })
    );

    this.subscription.push(
      this.cert.drogas.subscribe((resp: CertificadoRespuesta) => {
        this.maps.medicamentos = Droga.map(resp.registros);
        this.drogas = resp.registros;
        console.log(this.maps);
      })
    );

    this.subscription.push(
      this.cert.entes.subscribe((resp: CertificadoRespuesta) => {
        this.entes = resp.registros;
        this.entes.splice(0, 0, this.titular);
        this.maps.entes = Ente.map(this.entes);
      })
    );

    this.subscription.push(
      this.cert.responsables.subscribe((resp: CertificadoRespuesta) => {
        this.maps.distribuidoras = Responsable.map(resp.registros);
        this.responsables = resp.registros;
      })
    );


    this.subscription.push(
      this.cert.tipos.subscribe((resp: CertificadoRespuesta) => {
        this.maps.tipos = TipoCertificado.map(resp.registros);
        this.tipo = resp.registros;
      })
    );

    this.subscription.push(
      this.cert.downloadCertificado.subscribe((resp: any) => {
        let link = document.createElement("a");
        link.target = "_blank";
        link.download = "certificado.pdf";
        let urlObject = URL.createObjectURL(resp.resp);
        link.href = urlObject;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(urlObject);
        resp.cert["procesando_descarga"] = false;
      })
    );

    this.subscription.push(
      this.cert.detalle.subscribe((resp) => {
          const dialogRef = this.dialog.open(DetalleDialog, {
            data: {
              show_actions: resp.cert["show_actions"],
              read_only: resp.cert["estado"] != "Asignado",
              rechazado: resp.cert["estado"] == "Rechazado",
              rechazo_descripcion: resp.cert["motivo_rec"],
              certificado: resp.cert,
              items: resp.resp.registros
            }
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result["cerrar"] != undefined && result["cerrar"] == true)
          {
            result.cert["procesando"] = false;
            return;
          }
          if(result["confirmar"] != undefined && result["confirmar"] == true)
          {
            this.cert.confirmar(result.cert);
          }
          if(result["rechazar"] != undefined && result["rechazar"] == true)
          {
            this.cert.rechazar(result.cert, result.detalle);
          }
        });
      })
    );

    this.cert.getTipo();
    this.cert.getDrogas();
    this.cert.getEntes();
    this.cert.getResponsables();

    this.cert.getAsignados(true);
    this.cert.getRechazados(true);
    this.cert.getConfirmados(true);

  }

  ngOnDestroy() {
    this.subscription.forEach((e) => {
      e.unsubscribe();
    });
  }

  ngOnInit() {
  }

  liquidar() {
    this.liquidando = true;
    console.log(this.cantidad_a_liquidar);
    console.log(this.tipo_seleccionado);
    this.cert.liquidar(this.tipo_seleccionado, this.cantidad_a_liquidar);
  }

  descargar(cert: CertificadoAsignado) {
    cert["procesando_descarga"] = true;
    this.cert.download(cert);
  }

  ver_detalle(cert: CertificadoAsignado, show: boolean = false) {
    cert["procesando"] = true;
    cert["show_actions"] = show;
    this.cert.getDetalle(cert);
  }

}

import { Component } from '@angular/core';
import { PasswordComponent } from './password.component';

@Component({
    selector: 'app-email',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.css']
  })
  export class EmailComponent extends PasswordComponent {
    field = "email";
    field_type = "text";
  }
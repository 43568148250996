export class DescargaCategoria {
    nombre: string;
    aranceles: DescargaRegistro[];
}
export class DescargaRegistro {
    url: string;
    nombre: string;
    descripcion: string;
}
export class DescargaRespuesta {
    registros: DescargaCategoria[];
}

import { PendienteDialog } from './certificado.dialog.component';
import { DetalleDialog } from './certificado.dialog-detalle.component';
import { CertificadoService } from './../_services/certificados.service';
import { CertificadoPendiente, CertificadoRespuesta, CertificadoAsignado,
         TipoCertificado, Droga, Ente, Responsable } from './../_models/certificados';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-certificado',
  templateUrl: './certificado.component.html',
  styleUrls: ['./certificado.component.css']
})
export class CertificadoComponent implements OnInit, OnDestroy {

  pendientes: CertificadoPendiente[];
  asignados: CertificadoAsignado[];
  rechazados: CertificadoAsignado[];
  confirmados: CertificadoAsignado[];
  drogas: Droga[];
  entes: Ente[];
  responsables: Responsable[];
  tipo: TipoCertificado[];
  cantidad_a_liquidar: number = 0;
  tipo_seleccionado: number = 0;
  loadingPendientes: boolean = false;
  loadingAsignados: boolean = false;
  loadingRechazados: boolean = false;
  loadingConfirmados: boolean = false;
  liquidando: boolean = false;
  serverError: boolean = false;
  err_msg: string[] = [];
  titular: Ente = {'ente': 0, nombre: 'TITULAR', domicilio: ''};
  maps: any = {
    tipos: {},
    medicamentos: {},
    distribuidoras: {},
    entes: {}
  }
  private subscription = [];

  constructor(private cert: CertificadoService, private dialog: MatDialog) {
    this.loadingPendientes = true;
    this.loadingAsignados = true;
    this.loadingRechazados = true;
    this.loadingConfirmados = true;

    this.subscription.push(
      this.cert.liquidacion.subscribe((resp: string) => {
        console.log(resp);
        window.alert("Se han generado " + this.cantidad_a_liquidar + " certificados");
        this.liquidando = false;
        this.cantidad_a_liquidar = 0;
        this.loadingPendientes = true;
        this.cert.getPendientes();
      })
    );

    this.subscription.push(
      this.cert.error_servidor.subscribe((resp: CertificadoRespuesta) => {
        console.log(resp);
        this.serverError = true;
        this.loadingPendientes = false;
        this.loadingAsignados = false;
        this.loadingConfirmados = false;
        this.loadingRechazados = false;
        this.liquidando = false;
        if(resp.statussalida !== undefined && resp.statussalida !== "")
        {
          this.err_msg.push(resp.statussalida);
        }
      })
    );


    this.subscription.push(
      this.cert.asignacion.subscribe((resp: any) => {
        console.log(resp);
        if(resp.status === "")
        {
          let cert: CertificadoPendiente = resp["certificado"];
          cert.procesando = false;
          cert.mostrar = false;
          this.loadingAsignados = true;
          this.cert.getAsignados();
        }
      })
    );

    this.subscription.push(
      this.cert.pendientes.subscribe((resp: CertificadoRespuesta) => {
        resp.registros.forEach((r: CertificadoPendiente) => {
          r.procesando = false;
          r.mostrar = true;
          r.frascos = 1;
        });
        this.pendientes = resp.registros;
        this.loadingPendientes = false;
      })
    );

    this.subscription.push(
      this.cert.confirmados.subscribe((resp: CertificadoRespuesta) => {
        this.confirmados = resp.registros.sort((a, b) => {
          return b['certificado'] - a['certificado'];
        });
        this.loadingConfirmados = false;
      })
    );

    this.subscription.push(
      this.cert.rechazados.subscribe((resp: CertificadoRespuesta) => {
        this.rechazados = resp.registros.sort((a, b) => {
          return b['certificado'] - a['certificado'];
        });
        this.loadingRechazados = false;
      })
    );

    this.subscription.push(
      this.cert.asignados.subscribe((resp: CertificadoRespuesta) => {
        this.asignados = resp.registros.sort((a, b) => {
          return b['certificado'] - a['certificado'];
        });
        this.loadingAsignados = false;
      })
    );

    this.subscription.push(
      this.cert.drogas.subscribe((resp: CertificadoRespuesta) => {
        this.maps.medicamentos = Droga.map(resp.registros);
        this.drogas = resp.registros;
        console.log(this.maps);
      })
    );

    this.subscription.push(
      this.cert.entes.subscribe((resp: CertificadoRespuesta) => {
        this.entes = resp.registros;
        this.entes.splice(0, 0, this.titular);
        this.maps.entes = Ente.map(this.entes);
      })
    );

    this.subscription.push(
      this.cert.responsables.subscribe((resp: CertificadoRespuesta) => {
        this.maps.distribuidoras = Responsable.map(resp.registros);
        this.responsables = resp.registros;
      })
    );


    this.subscription.push(
      this.cert.tipos.subscribe((resp: CertificadoRespuesta) => {
        this.maps.tipos = TipoCertificado.map(resp.registros);
        this.tipo = resp.registros;
      })
    );

    this.subscription.push(
      this.cert.downloadCertificado.subscribe((resp: any) => {
        let link = document.createElement("a");
        link.target = "_blank";
        link.download = "certificado.pdf";
        let urlObject = URL.createObjectURL(resp.resp);
        link.href = urlObject;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(urlObject);
        resp.cert["procesando_descarga"] = false;
      })
    );

    this.subscription.push(
      this.cert.detalle.subscribe((resp) => {
          const dialogRef = this.dialog.open(DetalleDialog, {
            data: {
              show_actions: false,
              read_only: true,
              rechazado: resp.cert["estado"] == "Rechazado",
              rechazo_descripcion: resp.cert["motivo_rec"],
              certificado: resp.cert,
              items: resp.resp.registros
            }
        });
        resp.cert["procesando"] = false;
      })
    );

    this.cert.getTipo();
    this.cert.getDrogas();
    this.cert.getEntes();
    this.cert.getResponsables();
    
    this.cert.getPendientes();
    this.cert.getAsignados();
    this.cert.getRechazados();
    this.cert.getConfirmados();

  }

  actualizar_rechazados() {
    this.loadingRechazados = true;
    this.cert.getRechazados();
  }

  actualizar_confirmados() {
    this.loadingConfirmados = true;
    this.cert.getConfirmados();
  }


  ngOnDestroy() {
    this.subscription.forEach((e) => {
      e.unsubscribe();
    });
  }

  ngOnInit() {
  }

  liquidar() {
    this.liquidando = true;
    console.log(this.cantidad_a_liquidar);
    console.log(this.tipo_seleccionado);
    this.cert.liquidar(this.tipo_seleccionado, this.cantidad_a_liquidar);
  }

  asignar(cert: CertificadoPendiente) {

    const dialogRef = this.dialog.open(PendienteDialog, {
        // height: '400px',
        // width: '600px',
        data: {frascos: cert.frascos}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == null)
      {
        return;
      }
      
      cert.procesando = true;
      this.cert.asignar(cert, result["items"], result["elaboracion"], result["vencimiento"]);
    });
  }

  validarRegistro(cert: CertificadoPendiente) {
    let medicamento = (cert.medicamento != undefined && cert.medicamento != "")?true:false;
    // let ente = (cert.ente != undefined && cert.ente != "")?true:false;
    let distribuidora = (cert.distribuidora != undefined && cert.distribuidora != "")?true:false;
    return distribuidora && medicamento;
  }

  descargar(cert: CertificadoAsignado) {
    cert["procesando_descarga"] = true;
    this.cert.download(cert);
  }

  ver_detalle(cert: CertificadoAsignado) {
    cert["procesando"] = true;
    this.cert.getDetalle(cert);
  }

}

import { MatriculadoRegistro } from './../../_models/matriculado';
import { Component, OnInit } from '@angular/core';
import { MatriculadoService } from '../../_services/matriculado.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeDistribuidoraComponent implements OnInit {

  matriculado: MatriculadoRegistro;
  loading: boolean;
  showData: boolean;

  constructor(private service: MatriculadoService,
              private router: Router) {
      this.matriculado = new MatriculadoRegistro();
      this.loading = false;
      this.showData = false;
   }

  ngOnInit() {
    const matriculado = sessionStorage.getItem('matriculado');
    if ( matriculado !== null) {
      this.showData = true;
      this.matriculado = JSON.parse(matriculado);
    }
    this.loading = true;
      this.service.getInfo().first().subscribe(
        datos => {
            this.matriculado = datos.registros[0];
            sessionStorage.setItem('matriculado', JSON.stringify(this.matriculado));
            this.loading = false;
            this.showData = true;
      },
      error => {
        this.redirectLogin(error);
    });
  }
  redirectLogin(error: any) {
    if (error.status === 401 ) {
        this.router.navigate(['/login']);
    }
    this.loading = false;
  }
}

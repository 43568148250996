export class ArancelesCategoria {
    nombre: string;
    grupo: string;
    aranceles: ArancelRegistro[];
}
export class ArancelRegistro {
    codigo: number;
    nombre: string;
    galenos: number;
    info: string;
    tipo: string;
}
export class ArancelesRespuesta {
    galeno: number;
    registros: ArancelesCategoria[];
}

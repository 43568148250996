import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../_services';

enum PasswordStateView {
  INITIAL = 0,
  SEND_CODE = 1,
  CHANGE_VALUE = 2,
  COMPLETE = 3
}

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

  @Input('email') email = '';
  @Input('username') username = '';
  @Input('reset') onReset:EventEmitter<boolean> = null;
  @Output() onComplete = new EventEmitter<boolean>();

  field: string = 'password';
  field_type: string = "password";
  model: any = {};
  labels: any = {
    'password': {
      'btn_change': 'Cambiar Contraseña',
      'label_new_value': 'Nueva Contraseña',
      'label_repeat_value': 'Repetir Contraseña',
      'label_field_required': "La contraseña es necesaria",
      'reset_msg': "Los campos Nueva Contraseñas y Repetir Contraseña deben ser iguales.",
      'msg_success': "La contraseña ha sido cambiada, intente logearse al portal nuevamente."
    },
    'email': {
      'btn_change': 'Cambiar correo electrónico',
      'label_new_value': 'Nuevo correo electrónico',
      'label_repeat_value': 'Repetir correo electrónico',
      'label_field_required': "El correo electrónico es necesario",
      'reset_msg': "Los campos Nuevo correo electrónico y Repetir correo electrónico deben ser iguales.",
      'msg_success': "El correo electrónico ha sido actualizado."
    }
  }
  curr_state: PasswordStateView = PasswordStateView.SEND_CODE;
  send_code: PasswordStateView = PasswordStateView.SEND_CODE;
  change_password: PasswordStateView = PasswordStateView.CHANGE_VALUE;
  complete: PasswordStateView = PasswordStateView.COMPLETE;
  label: any;
  restore_error: boolean = false;
  restore_error_msg: string = '';
  reset_flag: boolean = false;
  reset_msg: string = '';
  disable_email_change: boolean = true;
  txt_button_send_email = 'Enviar Código';

  constructor(private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.label = this.labels[this.field];
    if(this.email == '') {
      this.disable_email_change = false;
    } else {
      this.model['email'] = this.email;
      this.disable_email_change = true;
      console.log(this.disable_email_change);
    }
    if(this.username != '') {
      this.model['username'] = this.username;
    }
    if (this.onReset != null) {
      this.onReset.subscribe(reset => this.restore_password())    
    }
  }

  restore_password() {
    this.curr_state = PasswordStateView.INITIAL;
  }

  _send_email() {
      this.restore_error = false;
      this.authenticationService.restore(this.model.email.trim()).subscribe(
          response => {
              this.reset_flag = true;
              this.reset_msg = response.mensaje;
              this.curr_state = PasswordStateView.CHANGE_VALUE
          },
          response => {
              this.restore_error = true;
              this.restore_error_msg = response.error.mensaje;
          }
      );
  }

  _change_password() {
      this.reset_flag = false;

      if(this.model.new_password.trim() != this.model.new_password_1.trim()){
        this.reset_flag = true;
        this.reset_msg = this.label['reset_msg'];
        return;
      }

      this.authenticationService.reset(
          this.model.username.trim(),
          this.model.new_password.trim(),
          this.model.resetid.trim()
      ).subscribe(
          response => {
              this.curr_state = PasswordStateView.COMPLETE;
          },
          response => {
              this.reset_flag = true;
              if (response.error.mensaje  === 'El resetid no es válido')
              {
                  this.reset_msg = "El código de verifcación no es válido.";
              }
              this.onComplete.emit(true);
          }
      );
  }
  _has_code(){
    this.curr_state = PasswordStateView.CHANGE_VALUE
  }
}
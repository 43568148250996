import { Item } from './../_models/certificados';
import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'pendiente-dialog',
  templateUrl: './pendiente.dialog.html',
  styleUrls: ['./certificado.component.css']
})
export class PendienteDialog {

  public items: Item[] = [];
  public elaboracion: string;
  public vencimiento: string;
  itemsForm = new FormArray([]);
  frascosForm = new FormGroup({
    elaboracion: new FormControl(''),
    vencimiento: new FormControl(''),
    items: this.itemsForm
  });


  constructor(private dialogRef: MatDialogRef<PendienteDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any)
  {
    for(let i = 0; i < data.frascos; ++i)
    {
      this.itemsForm.push(new FormGroup({
        lote: new FormControl(''),
        serie: new FormControl('')
      }));
      // this.items.push(new Item());
    }
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onAsignarClick(): void {

    let result = {
      elaboracion: this.frascosForm.get("elaboracion").value,
      vencimiento: this.frascosForm.get("vencimiento").value,
    }
    
    this.itemsForm.controls.forEach( (_item: FormGroup) => {
      let i: Item = new Item();
      i.lote = _item.get('lote').value;
      i.serie = _item.get('serie').value;
      this.items.push(i);
    });
    result['items'] = this.items;
    // console.log(result);
    this.dialogRef.close(result);
  }
}
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://cmvc.soportedlr.com.ar',
  // apiUrlComprobante: 'https://jefuitqwfg.execute-api.us-west-2.amazonaws.com/prod/pagos',
  apiUrlComprobante: 'https://cf03f9421j.execute-api.us-west-2.amazonaws.com/prod/pagos',
  apiKeyPagoFacil: '29eac3d9-728a-448b-8d06-2e24d94e91b8',
  endpointPagoFacil: "https://pagosenlinea.pagofacil.com.ar/api/pagosimplificado"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
